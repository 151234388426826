import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Button, Loading, Notify, Toast, Icon, Empty } from 'vant';
import request from "@/utils/request";

// 全局样式
import '@/styles/base.less';


// 注册vant组件
Vue.use(Button)
  .use(Loading)
  .use(Notify)
  .use(Toast)
  .use(Icon)
  .use(Empty)

Vue.config.productionTip = false

// 请求封装
Vue.prototype.$request = opt => {
  return request(opt);
}

// 提示信息封装
Vue.prototype.$message = text => {
  Toast(text);
}
Vue.prototype.$successMessage = text => {
  Toast.success(text);
}
Vue.prototype.$errorMessage = text => {
  Toast.fail(text);
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
