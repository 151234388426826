<template>
  <div class="wrap">
    <div class="error" v-show="status === -1">
      <van-empty
        image="error"
        :description="errorText"
      />
    </div>
    <div class="pre-pay" v-show="status === 0">
      <div class="header">
        <div class="text1">需付款</div>
        <div class="price">
          <span class="i">￥</span>
          <span class="num">{{ amount }}</span>
        </div>
      </div>
      <div class="goods-info">
        <div class="text1">套餐名称</div>
        <div class="name">{{ orderName }}</div>
      </div>
      <div class="buttons">
        <van-button class="button pay" @click="pay">继续支付</van-button>
        <van-button class="button query" @click="queryOrder(true)">查询支付结果</van-button>
      </div>
    </div>
    <div class="after-pay" v-show="status === 1">
      <van-empty
          class="custom-image"
          :image="require('@/assets/images/success.png')"
          image-size="8rem"
          description="支付成功"
      >
        <template #description>
          <div class="text" >
            <div class="line">支付成功</div>
            <div class="line">请刷新后台页面</div>
          </div>
        </template>
      </van-empty>
      <div class="back">
        <van-button class="button" @click="back">我知道了</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import {Base64} from "js-base64";
import {getUrlParams} from "@/utils";

const wx = require('weixin-js-sdk');
const authKeyCookieKey = 'authKey';
const orderNameKey = 'orderName';
const amountKey = 'amount';
const payNoCookieKey = 'payNo';
const apiUrlKey = 'apiUrl';
let timer = null;
let payQueryToast = null;
export default {
  name: "index",
  data() {
    return {
      status: 0, // 0 待支付，1支付成功，-1错误
      errorText: '系统错误，请稍后再试',
      amount: '--',
      orderName: '----------',
      payNo: '',
      authKey: '',
      apiUrl: '',
      transferUrl: '',
      appId: '',
      expire: 0,
      info: '',
    };
  },
  created() {
    if (this.$route.query.start) {
      // 第一次扫码进来，清除缓存，重新下单
      const query = JSON.parse(JSON.stringify(this.$route.query));
      query.start = undefined;
      Cookies.remove(authKeyCookieKey)
      Cookies.remove(orderNameKey)
      Cookies.remove(amountKey)
      Cookies.remove(payNoCookieKey)
      Cookies.remove(apiUrlKey)
      this.$router.push({
        name: 'index',
        query: query,
      })
    }

    const authKey = Cookies.get(authKeyCookieKey);
    const payNo = Cookies.get(payNoCookieKey);
    if (authKey) {
      this.apiUrl = Cookies.get(apiUrlKey);
      if (!this.apiUrl) {
        this.showError('订单已过期，请重新扫码');
      }
      this.amount = Cookies.get(amountKey) ?? this.amount;
      this.orderName = Cookies.get(orderNameKey) ?? this.orderName;
      this.authKey = authKey;
      if (payNo) {
        this.payNo = payNo;
        this.startQueryOrder();
      } else {
        this.pay();
      }
    } else {
      const toast = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
      });

      // 验证参数
      const query = this.$route.query;
      const key = query.key;
      if (!Base64.isValid(key)) {
        this.showError('支付校验错误，请重新扫码');
        return;
      }

      let keyMap = Base64.decode(key);
      keyMap = getUrlParams(keyMap);
      let check = true;
      ['apiUrl', 'transferUrl', 'appId', 'expire', 'info'].forEach(param => {
        if (!keyMap || !keyMap[param]) {
          check = false;
        }
      });
      if (!check) {
        this.showError('支付校验错误，请重新扫码');
        return;
      }
      this.apiUrl = keyMap.apiUrl;
      Cookies.set(apiUrlKey, this.apiUrl);
      this.transferUrl = keyMap.transferUrl;
      this.appId = keyMap.appId;
      this.info = keyMap.info;
      this.expire = parseInt(keyMap.expire);

      if (!query.code) {
        const currentUrl = document.URL;
        const redirectUrl = encodeURIComponent(this.transferUrl + '?url=' + encodeURIComponent(currentUrl));
        window.location.href =
            "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + this.appId + "&redirect_uri=" + redirectUrl + "&response_type=code&scope=snsapi_base&state=STATE#wechat_redirec";
      } else {
        this.auth()
          .then(() => {
            toast.clear()
            this.pay();
            const query = JSON.parse(JSON.stringify(this.$route.query));
            query.code = undefined;
            query.start = undefined;
            this.$router.push({
              name: 'index',
              query: query,
            })
          })
          .catch(() => {
            toast.clear()
          });
      }

    }
  },
  methods: {
    // 参数认证，获取订单
    auth() {
      return new Promise((resolve, reject) => {
        if (!this.expire || ((new Date()).getTime() - this.expire > 10 * 60 * 1000)) {
          this.showError('订单已过期，请重新扫码');
          reject();
          return;
        }

        let queryParams;
        if (this.$route.query.encode === 'query') {
          if (!Base64.isValid(this.info)) {
            this.showError('支付校验错误，请重新扫码');
            reject();
            return;
          }
          queryParams = {
            ...getUrlParams(Base64.decode(this.info)),
            code: this.$route.query.code,
          };
        } else {
          queryParams = {
            info: this.info,
            code: this.$route.query.code,
          };
        }


        this.$request({
          url: this.apiUrl + '/pay/auth',
          params: queryParams,
        })
            .then(res => {
              console.log(res);
              if (res.error === 0) {
                this.orderName = res.data.orderName;
                this.amount = res.data.amount;
                this.authKey = res.info.authKey;
                Cookies.set(authKeyCookieKey, this.authKey);
                Cookies.set(amountKey, this.amount);
                Cookies.set(orderNameKey, this.orderName);
                resolve();
              } else if(res.error === 401) {
                this.showError('支付校验错误，请重新扫码');
                reject();
              } else{
                this.showError(res.message)
                reject();
              }
            })
            .catch(error => {
              this.showError('系统错误，请稍后再试');
              console.log('请求认证信息失败', error);
              reject();
            })
      })
    },
    // 调起支付
    pay() {
      this.$request({
        url: this.apiUrl + '/pay/wx',
        method: 'post',
        data: {
          authKey: this.authKey,
        }
      })
        .then(res => {
          if (res.error === 0) {
            const config = res.data;
            this.payNo = res.info.payNo;
            Cookies.set(payNoCookieKey, this.payNo);
            console.log('config', config);

            // wxjssdk 调起支付
            /*wx.chooseWXPay({
              timestamp: config.timestamp,
              nonceStr: config.nonceStr, // 支付签名随机串，不长于 32 位
              package: config.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
              signType: config.signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
              paySign: config.paySign, // 支付签名
              success: res => {
                console.log('微信支付成功', res);
                // 支付成功后查询订单
                payQueryToast = this.$toast.loading({
                  duration: 0, // 持续展示 toast
                  forbidClick: true,
                });
                this.startQueryOrder();
              }
            });*/

            window.WeixinJSBridge.invoke(
              'getBrandWCPayRequest',
              config,
              res => {
                try {
                  if(res.err_msg === "get_brand_wcpay_request:ok" ) {
                    console.log('微信支付成功', res);
                    // 支付成功后查询订单
                    payQueryToast = this.$toast.loading({
                      duration: 0, // 持续展示 toast
                      forbidClick: true,
                    });
                    this.startQueryOrder();
                  }
                } catch (e) {
                  // alert(e.toString());
                }
              }
          );
          } else {
            this.showError('系统错误，请稍后再试');
          }
        })
        .catch(error => {
          this.showError('系统错误，请稍后再试');
          console.log('请求支付错误', error)
        })
    },
    // 轮询订单
    startQueryOrder() {
      if (timer) {
        clearInterval(timer);
      }
      timer = setInterval(() => {
        this.queryOrder();
      }, 2000);
    },
    // 查询订单
    queryOrder(click = false) {
      this.$request({
        url: this.apiUrl + '/pay/query',
        params: {
          payNo: this.payNo,
        }
      })
        .then(res => {
          if (res.error === 0) {
            if (res.data.status === 1) {
              this.status = 1;
              if (payQueryToast) {
                payQueryToast.clear();
                payQueryToast = null;
              }
              if (timer) {
                clearInterval(timer);
              }
            } else {
              if (click) {
                this.$errorMessage('未查询到订单');
              }
            }
          } else {
            console.log('查询错误', res.message);
          }
        })
        .catch(error => {
          console.log('查询失败', error);
        })
    },
    // 退出支付
    back() {
      if (window.WeixinJSBridge) {
        window.WeixinJSBridge.call('closeWindow');
      } else {
        window.history.back();
      }
    },
    // 提示消息
    showError(errorText) {
      this.status = -1;
      this.errorText = errorText;
      this.$toast.clear();
    },

  },
}
</script>

<style lang="less" scoped>
  .wrap{
    padding: 1.8rem 2rem 0;
    .header{
      .text1{
        color: #666666;
      }
      padding: 1rem 0 0;
      .price{
        .i{
          font-size: 1.8rem;
        }
        .num{
          font-size: 2.6rem;
        }
      }
    }
    .goods-info{
      padding: 1.2rem 0 0;
      display: flex;
      font-size: .96rem;
      justify-content: space-between;
      color: #666666;
      align-items: center;
    }
    .buttons{
      position: fixed;
      width: 100%;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      padding: 1.2rem 2rem 3.2rem;
      box-sizing: border-box;
      .button{
        margin-bottom: 1.2rem;
        border-radius: 1.5rem;
        border: 1px solid #507acd;
        &.pay{
          background-color: #487bdf;
          color: #ffffff;
        }
        &.query{
          color: #487bdf;
        }
      }
    }

    .error{
      padding: 2rem 0 0;

    }

    .after-pay{
      padding: 2rem 0 0;
      .text{
        padding: .8rem 0 0;
        font-size: 1rem;
        line-height: 1.8rem;
      }
      .back{
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
        padding: 1.2rem 2rem 3.5rem;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .button{
          margin-bottom: 1.2rem;
          border-radius: 1.5rem;
          border: 1px solid #58bd6a;
          background-color: #58bd6a;
          color: #ffffff;
        }
      }
    }
  }
</style>

<style lang="less">
  .wrap{
    .error{
      .van-empty__description{
        font-size: 1rem;
      }
    }
  }
</style>
